import React from 'react';
import { Layout, Seo } from 'components/common';
import { Intro, Projects } from 'components/sections';
import { graphql, useStaticQuery } from 'gatsby';

const ProjectsPage = ({ pageContext }) => {
  const { introImageFile } = useStaticQuery(graphql`
    {
      introImageFile: file(
        relativePath: { eq: "illustrations/ground-zero.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
        }
      }
    }
  `);

  return (
    <Layout context={pageContext}>
      <Seo />
      <Intro
        title="Projects"
        subtitle="I have worked on a number of projects, some of which are listed below."
        image={{
          alt: 'Projects Hero Image',
          image: introImageFile.childImageSharp.gatsbyImageData,
        }}
      />
      <Projects />
    </Layout>
  );
};

export default ProjectsPage;
